import { graphql, Link } from 'gatsby';
import React, { useState } from 'react';
import ArrowIcon from 'components/atoms/ArrowIcon';
import Layout from 'components/base/Layout';
import SEO from 'components/base/SEO';
import CtaSection from 'components/organisms/CtaSection';
import FullWidthResourceCard from 'components/organisms/FullWidthResourceCard';
import NewsletterCtaSection from 'components/organisms/NewsletterCtaSection';

const ProductUpdatesContent = ({ resources = [], newsletterCta, cta }) => {
  const [showAll, setShowAll] = useState(false);
  return (
    <>
      <div className="u-container pt-32 md:pt-48 mb-12 md:mb-24 px-6 lg:px-md xl:px-0">
        <Link
          className="inline-flex items-center text-orange font-semibold text-lg tracking-tighter transition hover:text-dark-grey focus:text-dark-grey"
          to="/resources"
        >
          <ArrowIcon className="transform rotate-180 mr-4 -mt-px" /> Back to
          Resources
        </Link>
        <h1 className="u-h1 mt-6 md:mt-8">Product Updates</h1>
      </div>

      {resources
        .slice(0, 1)
        .map(({ title, date, imageUrl, url, description }, index) => (
          <FullWidthResourceCard
            key={index}
            title={title}
            date={date}
            imageUrl={imageUrl}
            url={url}
            type="product-update"
            description={description}
          />
        ))}

      <NewsletterCtaSection
        headline={newsletterCta?.headline}
        background="bg-orange text-white"
      />

      {resources.length > 1 && (
        <div className="pt-20 md:pt-32">
          {resources
            .slice(1, showAll ? undefined : 5)
            .map(({ title, date, imageUrl, url, description }, index) => (
              <FullWidthResourceCard
                key={index}
                title={title}
                date={date}
                imageUrl={imageUrl}
                url={url}
                type="product-update"
                description={description}
              />
            ))}
        </div>
      )}

      {resources.length > 5 && !showAll && (
        <div className="pt-20 pb-36 text-center">
          <button
            className="bg-dark-grey text-white py-5 px-10 text-lg tracking-tighter leading-none hover:opacity-75 focus:opacity-75 transition rounded-full font-semibold"
            onClick={() => setShowAll(true)}
          >
            Load more
          </button>
        </div>
      )}

      <CtaSection headline={cta?.headline} />
    </>
  );
};

const RESOURCES_FIXTURE = [
  {
    title: 'Product Update',
    date: '2020-05-06T07:00:32.311Z',
    imageUrl: '/uploads/video-labor-day.jpg',
    url: '/product-updates',
    description:
      'Mauris aliquet varius nec vitae. Malesuada parturient aliquet auctor nunc eleifend suscipit viverra. Mattis facilisis viverra turpis.',
  },
];

export const ProductUpdatesPreview = ({ entry }) => {
  return (
    <ProductUpdatesContent
      {...entry.get('data').toJS()}
      resources={RESOURCES_FIXTURE}
    />
  );
};

const ProductUpdatesPage = ({
  data: {
    markdownRemark: { frontmatter },
    updates,
  },
}) => {
  const [showAll, setShowAll] = useState(false);

  const { seo } = frontmatter;

  const resources = updates.edges.map(({ node }) => {
    const { title, date, heroImage, slug, description } =
      node.frontmatter || {};

    return {
      title,
      date,
      imageUrl: heroImage,
      url: `/product-updates/${slug}`,
      description,
    };
  });

  return (
    <Layout>
      <SEO
        title={seo?.title}
        description={seo?.description}
        openGraphImage={seo?.openGraphImage}
      />

      <ProductUpdatesContent {...frontmatter} resources={resources} />
    </Layout>
  );
};

export const pageQuery = graphql`
  query ProductUpdatesPageQuery {
    markdownRemark(fileAbsolutePath: { regex: "/pages/product-updates/" }) {
      frontmatter {
        seo {
          title
          description
          openGraphImage
        }
        newsletterCta {
          headline
        }
        cta {
          headline
        }
      }
    }
    updates: allMarkdownRemark(
      filter: {
        frontmatter: {
          templateKey: { eq: "product-update" }
          status: { eq: "public" }
        }
      }
      sort: { fields: [frontmatter___date], order: [DESC] }
    ) {
      edges {
        node {
          frontmatter {
            title
            date
            slug
            heroImage
            description
          }
        }
      }
    }
  }
`;

export default ProductUpdatesPage;
